* {
  box-sizing: border-box;
}
:root {
  --main-red-color: #c41d18;
  --main-white-color: #fff;
  --main-black-color: #20201f;
  --main-green-color: #6dd627;
  --blured-black-color: #20201f55;
  --blured-white-color: #ffffff99;
  --blured-red-color: #c41d1844;
  --main-font-family: "Raleway", "Roboto", "Oxygen", sans-serif;
  --headings-font-family: "Luckiest Guy", cursive;
  --font-weight-default: 500;
  --font-weight-headings: 400;
  --mobile-screen: 464px;
  --tablet-screen: 1024px;
  --border-radius-primary: 5px;
  --border-radius-secondary: 15px;
  --font-size-heading: 48px;
  --font-size-name: 24px;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--main-font-family);
  font-optical-sizing: auto;
  font-weight: var(--font-weight-default);
  font-style: normal;
  color: var(--main-black-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

p {
  margin: 0;
  padding: 0;
}

a {
  font-style: normal;
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
